.title[data-v-aa1b53f9] {
  height: 75px;
  line-height: 75px;
  margin-bottom: 0;
}
.table-container[data-v-aa1b53f9] {
  height: 100%;
  overflow: hidden;
}
.organization-and-filter[data-v-aa1b53f9] {
  display: flex;
  flex-direction: row;
  padding: 8px 0;
}
