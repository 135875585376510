.toolbar[data-v-f977f094] {
  display: flex;
  flex-direction: var(--f977f094-flexDirection);
  justify-content: space-between;
}
.toolbar .tool_left[data-v-f977f094] {
  display: flex;
  flex-direction: var(--f977f094-flexDirection);
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.toolbar .tool_left .organization-select[data-v-f977f094] {
  white-space: nowrap;
  margin: 0 8px 8px 0;
}
.toolbar .tool_left .input-container[data-v-f977f094] {
  white-space: nowrap;
  margin: 0 8px 8px 0;
}
.toolbar .tool_left .input-container .input[data-v-f977f094] {
  margin-bottom: var(--f977f094-margin);
  width: 120px;
}
.toolbar .tool_left .applicable-filter[data-v-f977f094] {
  margin-bottom: 8px;
  margin-right: 8px;
}
.toolbar .tool_right[data-v-f977f094] {
  display: flex;
  flex-direction: var(--f977f094-flexDirection);
  justify-content: flex-end;
  flex-wrap: wrap;
}
.toolbar .tool_right .tool_dropdown_menu[data-v-f977f094] {
  margin: 0 20px 8px 0;
}
.toolbar .tool_right .tool_toggle_State[data-v-f977f094] {
  margin: 0 20px 8px 0;
}
.toolbar .tool_right .new_worklist[data-v-f977f094] {
  margin-bottom: 8px;
}
[data-v-f977f094] .date-input {
  margin-bottom: var(--f977f094-margin);
}
